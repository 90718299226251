import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BauturaPage = () => (
  <Layout>
    <SEO title="Candy Bar" />
    <div className="drawer article-content">
      <h1 className="article-title">Candy Bar</h1>

      <section>
        <p>
          Dacă înainte prăjiturile la nunți erau servite în platouri așezate pe
          fiecare masă, în ultimii ani s-a conturat ideea de candy bar, în care
          un colț este astfel aranjat încât să fie zona dedicată prajiturilor
          și/sau fructelor.
        </p>
        <p>
          Singurele dezavantaje ale candy bar-ului sunt costul ridicat (va
          trebui să te asiguri că ai destule prăjiturele pentru toți invitații,
          arată extrem de urât o nuntă în care candy bar-ul conține 5 cupcakes
          si ai mulți invitați) și necesitatea unui spațiu destul de mare pentru
          a așeza mesele cu prăjituri, cât și un spațiu accesibil pentru toți
          invitații.
        </p>
        <p>
          Avantajele: nu mai înghesuim mesele și cu platourile de prăjituri, nu
          trebuie să te “lupți” cu vecinii de masă pentru prăjitura preferată,
          poți încadra candy bar-ul în tematica nunții și arată încredibil de
          frumos.
        </p>
      </section>

      <h3 className="paragh-title">De ce ar trebui să ții cont:</h3>
      <section>
        <ul>
          <li>
            cantitatea de prăjituri să fie raportată la numărul de invitați.
            Sigur că nu vei avea 200 de cupcakes, însă nici să ai 10 macaroons,
            10 cupcakes și 20 de bezele pentru o nuntă de 200 de persoane. Sigur
            că nu toți au aceleași gusturi, de aceea este important să fie
            variat, însă să te asiguri că este și suficient
          </li>
          <li>
            prăjiturile ar trebui să fie în varianta mici și ușor de mâncat.
            Poți alege: orice miniprăjitura, bezele, macaroons, cupcakes, tarte
            cu fructe, mini ecler, praline, cakepops, diverse deserturi în
            pahare (mousse, semi freddo)
          </li>
          <li>oamenii își pot personaliza băuturile, nu au meniu impus</li>
          <li>
            la candy bar poți alătura și un bar de fructe și din nou vei elibera
            loc pe masă
          </li>
          <li>
            poți să personalizezi cu tematica nunții, de la suporturi de
            prăjituri asortate, la prăjituri cu glazură colorată
          </li>
          <li>
            poți face un candy bar specializat, de exemplu doar cu donuts sau
            doar cu tarte/plăcinte
          </li>
          <li>
            cei de la cofetării pun etichete cu numele prăjiturilor, așadar
            fiecare invitat va știi ce alege
          </li>
        </ul>
      </section>

      <section>
        <p>
          Poți alege candy bar-ul din același loc de unde achiziționezi și
          tortul sau dacă vrei ceva anume, îl poți personaliza. Mai există și
          varianta cu prajituri home made, însă este destul de dificil să
          pregătești, depozitezi, transporți atâtea prăjituri, așa că mai bine
          te bazezi pe o cofetărie.
        </p>
        <p>
          De la momentul sosirii invitaților până la primul fel de mâncare, de
          obicei sunt cam 2h, plus că nu toți invitații stau până la servirea
          tortului și luând în calcul varietatea prăjiturilor și aspectul unui
          candy bar, cu siguranță este o idee de luat în calcul.
        </p>
      </section>
      
      <div className="nav-section">
        <Link to="/bauturaCocktail">◀ Băutura la nuntă și Cocktail bar </Link>
        <Link to="/marturiile">▶ Mărturiile</Link>
      </div>
    </div>
  </Layout>
)

export default BauturaPage
